<template>
  <div style='padding: 0.5%'>
    <el-table v-bind='$attrs'
              v-on='$listeners'
              :data='dataList'
              :span-method='objectSpanMethod'
              :summary-method='getSummaries'
              show-summary
              border>
      <el-table-column
        prop='index'
        label='序号'
        align='center'
        width='60' fixed='fixed'>
        <template slot-scope='{$index}'>
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop='group_name'
        label='组别'
        align='center'
        min-width='100'>
        <template slot-scope='{row}'>
          <!--          <i class='el-icon-document-copy'></i>-->
          <el-link type='primary' @click='editBonus(row)' v-if='editGroup'>{{ row.group_name }}
             <i class='el-icon-edit'></i></el-link>
          <span v-else>{{ row.group_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='gmv_bonus'
        label='GMV团队奖金'
        align='center'
        min-width='110'>
        <template slot-scope='scope'>
          <span class='money'>{{ valFormat(scope.row.gmv_bonus * 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='revenue_bonus'
        label='营收团队奖金'
        align='center'
        min-width='110'>
        <template slot-scope='scope'>
          <span class='money'>{{ valFormat(scope.row.revenue_bonus * 1) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop='total_bonus'
        label='总奖金'
        align='center'
        min-width='110'>
        <template slot-scope='scope'>
          <span class='money'>{{ valFormat(scope.row.total_bonus || 0) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='position'
        label='岗位'
        align='center'
        min-width='100'>
      </el-table-column>
      <el-table-column
        prop='name'
        label='姓名'
        align='center'
        min-width='100'>
        <template slot-scope='{row}'>
          <span>
            {{ row.name }}
            <span style='color: #E6A23C;margin-left: 5px' v-if='row.nickname&&row.nickname!==row.name'>({{ row.nickname
              }})</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop='ratio'
        label='比例'
        align='center'
        min-width='100'>
        <template slot-scope='{row,column}'>
          <span class='ratio'>{{ row[column.property] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='amount'
        label='奖金'
        align='center'
        min-width='100'>
        <template slot-scope='{row}'>
              <span class='money'>
                {{ valFormat((row.ratio * row.total_bonus) / 100) }}
              </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'BonusItemExpandTable',
  data() {
    return {
      mergeFields: ['group_name', 'trend_bonus','gmv_bonus', 'revenue_bonus', 'total_bonus'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {}//合并的位置记录
    }
  },
  props: {
    groupData: {
      type: Object,
      required: true
    },
    editGroup: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    // 表格数据
    dataList() {
      let tableData = []
      this.groupData.items.forEach((item) => {
        item.revenue_bonus = this.groupData.revenue_bonus / 1
        // item.trend_bonus = this.groupData.trend_bonus / 1
        item.gmv_bonus = this.groupData.gmv_bonus / 1
        item.total_bonus = this.groupData.revenue_bonus / 1 + this.groupData.gmv_bonus / 1
        item.group_id = this.groupData.group_id
        item.group_name = this.groupData.group_name
        item.bill_id = this.groupData.id
        tableData.push(item)
      })
      return tableData
    }
    // group() {
    //   return {group_id: this.groupData.group_id, group_name: this.groupData.group_name, bill_id: this.groupData.id}
    // }
  },
  methods: {
    valFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // if (index === 2) {
        //   sums[index] = '合计'
        //   return
        // }
        if (['ratio', 'amount'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'ratio':
              sums[index] = `${(sums[index])} %`
              break
            case 'amount':
              sums[index] = `￥${this.valFormat(sums[index])}`
              break
            default:

              break
          }
        }
      })

      return sums
    },
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let len = this.dataList.length
      const fields = this.mergeFields
      if (fields.indexOf(column.property) > -1) {
        if (rowIndex % len === 0) {
          return {
            rowspan: len,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    editBonus(row) {
      //修改成员
      this.$emit('editBonus', row)
    }
  }
}
</script>

<style scoped>

</style>
