<template>
  <div>
    <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
               width='1000px' :title='dialogTitle' :append-to-body='true'>
      <div class='card'>
        <div class='card-box'>
          <el-form :model='formData' label-width='100px' label-position='right'>
            <el-form-item label='选择部门：'>
              <el-cascader
                v-model='formData.dept_id'
                :options='options'
                placeholder='请选择部门（公司内当前组织架构）'
                :props="{ expandTrigger: 'hover' ,value: 'dpt_id', label: 'name', multiple:false,emitPath:false,checkStrictly: true}"
                clearable
                collapse-tags
                @change='selectDept'
                style='width: 400px'></el-cascader>

              <!--                <CompanySelect ref='refDeptSelect' @handleSelect='selectDept'></CompanySelect>-->
            </el-form-item>
            <el-form-item label='部门成员：'>
              <div style='display: block;'>
                <span v-if='formData.dept_name'> 『{{ formData.dept_name || '' }}』</span>
                <el-checkbox v-model='checkAll' @change='handleCheckAll'>选中『在职人员』</el-checkbox>
                <div class='member-box' v-if='formData.members.length>0'>
                  <el-checkbox-group v-model='formData.selectMembers' @change='handleChange'>
                    <el-checkbox v-for='(member,index) in formData.members' :key='member.userid'
                                 :label='member'>
                      <span :title='`钉钉用户ID：${member.userid}`'>  {{ index + 1 }}）{{ member.name }}</span>
                      <span v-if='member.position'> 『{{ member.position }}』</span>
                      <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                      <span v-else>({{ member.is_leave_alias }})</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class='card-box'>
          <div class='member-box'>
            <el-tag class='member' size='medium' effect='plain' type='primary' v-for='(member,index) in selectMembers'
                    :key='member.name'
                    style='margin-left: 10px' closable @close='handleTagClose(member)'>
              {{ index + 1 }}) {{ member.name || '' }}
            </el-tag>
          </div>
          <div style='text-align: center;padding: 10px;'>
            <el-button round type='primary' size='medium' @click='handleAdd'>确认添加</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 部门成员选择器
export default {
  name: 'SelectMembers',
  data() {
    return {
      dialogTitle: '添加成员',
      loadingCommit: false,
      dialogVisible: false,
      data: [],
      options: [],
      formData: {
        dept_id: null,
        dept_name: null,
        selectMembers: [],
        members: []
      },
      checkAll: false,
      selectMembers: []

    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.getList()
    },
    async getList() {
      let { list } = await this.$api.getDepartmentAll(this.type)
      this.data = list || []

      this.calcOptions()
    },
    calcOptions() {
      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        // console.log(dept)
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          // let deptSelect = this.data.find(value => value.dpt_id === this.value)
          // console.log(deptSelect)
          this.$emit('handleSelect', this.value)
        }
      }
    },
    getNodeName(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.name : ''
    },

    selectDept(dept_id) {
      //项目部2-组3
      this.checkAll = false
      if (dept_id) {
        this.formData.dept_id = dept_id
        this.formData.dept_name = this.getNodeName(dept_id)
        this.getGroupMembers({ dpt_id: dept_id })

      } else {
        this.formData.dept_id = null
        this.formData.dept_name = null
      }
    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {
      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      this.formData.members = list || []
      return members
    },
    defaultSelected() {

      this.formData.member_ids = []
      //默认勾选在职
      if (this.defaultSelectNoLeave) {
        this.formData.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.member_ids.push(member)
          }
        })
        this.checkAll = true
        //切换
        this.handleChange()
      }
    },
    handleChange() {
      this.formData.selectMembers.forEach((member) => {
        if (this.selectMembers.findIndex(value => value.id === member.id) === -1)
          this.selectMembers.push(member)
      })
      // this.selectMembers = [...this.selectMembers, ...this.formData.selectMembers]
    },
    handleCheckAll() {
      this.formData.selectMembers = []
      if (this.checkAll) {
        //默认勾选在职
        this.formData.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.selectMembers.push(member)
          }
        })
      }
      this.handleChange()
    },
    handleTagClose(member) {
      const pIndex = this.selectMembers.findIndex(value => value.id === member.id)
      this.selectMembers.splice(pIndex, 1)
    },
    async handleAdd() {
      this.$notify.success(`选择 ${this.selectMembers.length} 个成员`)
      this.$emit('selectMembers', this.selectMembers)
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.card .card-box {
  flex: auto;
  width: 50%;

}

.card .card-box .member-box {
  border: #f2f2f2 1px dashed;
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
}

.card .card-box .member-box .member {
  margin-bottom: 10px;
}
</style>
