<template>
  <div>
    <!--    筛选条件-->
    <bonus-search @doSearch='doSearch' />

    <!--    列表-->
    <bonus-list ref='refBonusList' />

    <!--    提示-->
    <Tips :data='tipList' title='提示' />

  </div>
</template>

<script>
import BonusSearch from './BonusSearch'
import BonusList from './BonusList'

export default {
  name: 'BonusGroupIndexView',
  components: {
    BonusList,
    BonusSearch
  },
  data() {
    return {
      tipList: [
        '数据来源：项目组绩效-设置了团队奖金的项目组'
      ]
    }
  },
  methods: {
    doSearch(val) {
      if (this.$refs['refBonusList'])
        this.$refs['refBonusList'].show(val)
    }
  }
}
</script>

<style scoped>

</style>
