<template>
  <div class='main-page-content'>
    <el-tabs v-model='activeName' class='single-col-tab' tab-position='left'>
      <el-tab-pane label='项目组' name='bonusGroup' v-if='userPermissions.indexOf("pa_group_bonus")>-1'>
       <BonusGroupIndexView></BonusGroupIndexView>
      </el-tab-pane>
      <el-tab-pane label='总监' name='commissioner' v-if='userPermissions.indexOf("pa_bonus_chief")>-1'>
        <chief-bonus></chief-bonus>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import ChiefBonus from '@/pages/pa/bonus/ChiefBonus.vue'
import BonusIndex from '@/pages/performance/bonus/BonusIndex'
import { mapGetters } from 'vuex'
import BonusGroupIndexView from '@/pages/pa/bonus/group/IndexView'

export default {
  name: 'PaBonusIndex',
  components: { BonusGroupIndexView, BonusIndex, ChiefBonus, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      activeName: 'bonusGroup'
    }
  },
  methods: {
    // handleTabClick(){}
  }
}
</script>

<style >
/**
绩效页面
 */
.single-col-tab .el-tabs__item {
  width: 30px;
  line-height: 30px;
  font-size: 18px;
  writing-mode: vertical-lr;
  height: 160px;
  padding-bottom: 20px;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
}
</style>
